:root {
    --main-color: #00FFCC;
    --secondary-color: #223066;
    --tertiary-color: #FFD1A8;
    --contrast-color: #2C2929;
    --white-color: #ffffff;
    font-size: 16px;
    text-align: center;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(../src/assets/fonts/Montserrat-Light.ttf);
}

@font-face {
    font-family: 'MontserratRegular';
    src: url(../src/assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
    font-family: [Montserrat];
    src: url(../src/assets/fonts/Montserrat-Thin.ttf);
}

@font-face {
    font-family: [Montserrat];
    src: url(../src/assets/fonts/Montserrat-Black.ttf);
}

@font-face {
    font-family: 'MontserratBold';
    src: url(../src/assets/fonts/Montserrat-Bold.ttf);
    font-weight: 700;
    font-size: 1.5rem;
}

@font-face {
    font-family: [Montserrat];
    src: url(../src/assets/fonts/Montserrat-ExtraBold.ttf);
}

* {
    font-family: 'Montserrat';
}

.toggle {
    position: relative;
    top: -8px !important;
    left: 0.5rem !important;
}

.sombra{
    background: #FFFFFF;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 19.0044px;
}
.treme-animation {
    animation: treme 0.1s;
    animation-iteration-count: 3;
  }

.bg-blue-iserv{
    background-color: #223066;
}
.bg-blue-secondary-iserv{
    background-color: #00FFCC;
}
  
  @keyframes treme {
    0% {margin-left: 0;}
    25% {margin-left: 5px;}
    50% {margin-left: 0;}
    75% {margin-left: -4px;}
    100% {margin-left: 0;}
  }


  /********************SPINNER LOADER***********************/

.overlay {
    opacity: 0.8;
    background-color: #ccc;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 15000;
    display: flex
}

.loader {
    margin: auto;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid var(--main-color);
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

#desafioROW > div.col-md-6 > div{
    width: 100%;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}